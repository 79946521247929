import { FC } from "react";
import { Col } from "components/Col";
import { Row } from "components/Row";
import { SectionWithBG } from "components/Section";
import styled from "styled-components";
import { ReactComponent as SmartLogo } from "./logo smart.svg";
import { ReactComponent as GolemioLogo } from "./logo golemio.svg";
import { ReactComponent as IPRLogo } from "./logo IPR.svg";
import { ReactComponent as PrahaLogo } from "./logo praha.svg";
import { ReactComponent as PrgLogo } from "./logo prgai.svg";
import { ReactComponent as CDLogo } from "./logo cesko digital.svg";
import { queries } from "utils/queries";
import { GradientA } from "components/GradientLink";

const IconWrapperCol = styled(Col)`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1560px) {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }

  ${queries.onlySM} {
    max-width: 600px;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: 13;

    & a {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  & svg {
    fill: #fff;
  }
`;

const OrderedLink = styled(GradientA)<{ order: number }>`
  ${queries.onlySM} {
    order: ${({ order }) => order};
  }
`;

export const Partners: FC = () => {
  return (
    <SectionWithBG id="partners">
      <Row>
        <Col startSM={1}>
          <h2>Partners</h2>
        </Col>
      </Row>
      <Row>
        <IconWrapperCol startMD={1} endMD={11}>
          <GradientA href="https://smartprague.eu/" target="_blank" rel="noreferrer">
            <SmartLogo />
          </GradientA>
          <OrderedLink href="https://golemio.cz/" target="_blank" rel="noreferrer" order={4}>
            <GolemioLogo />
          </OrderedLink>
          <OrderedLink href="https://www.iprpraha.cz/" target="_blank" rel="noreferrer" order={2}>
            <IPRLogo />
          </OrderedLink>
          <OrderedLink href="https://www.praha.eu/" target="_blank" rel="noreferrer" order={3}>
            <PrahaLogo />
          </OrderedLink>
          <OrderedLink href="https://prg.ai/" target="_blank" rel="noreferrer" order={5}>
            <PrgLogo width={213} height={128} />
          </OrderedLink>
          <OrderedLink href="https://cesko.digital/" target="_blank" rel="noreferrer" order={6}>
            <CDLogo width={213} height={128} />
          </OrderedLink>
        </IconWrapperCol>
      </Row>
    </SectionWithBG>
  );
};
