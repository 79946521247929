import { FC } from "react";
import { ArrowIcon } from "components/ArrowIcon";
import { Row } from "components/Row";
import styled from "styled-components";
import { GradientA } from "components/GradientLink";
import { NavLinks } from "components/NavLinks";
import { Col } from "components/Col";
import { queries } from "utils/queries";

const ArrowButton = styled.button`
  font-weight: bold;
  font: 2.75rem #fff;
  background: none;
  border: none;
  height: 3rem;
  top: 16.06rem;

  & svg {
    fill: #7788c3;
  }
  &:hover svg {
    fill: url(#paint0_linear);
  }
`;
const RotatedButton = styled(ArrowButton)`
  text-align: left;

  & svg {
    transform: rotate(180deg);
    ${queries.onlySM} {
      height: 1.1rem;
    }
  }
`;

const PreviousYear = styled.a`
  font-weight: bolt;
  font-size: 2.37rem;
  line-height: 3rem;
  color: #fff;
  padding-left: 2rem;

  ${queries.onlySM} {
    font-size: 1.5rem;
    padding-left: 0.5rem;
  }
  &:hover {
    text-decoration: underline;
  }
`;
const LinksBox = styled.div`
  text-align: right;
  font-family: "Montserrat";
  font-size: 2.37rem;
  line-height: 3rem;
  width: 100% important!;
  ${queries.onlySM} {
    font-size: 1.5rem;
  }
`;

const ActiveYear = styled(GradientA)`
  background: linear-gradient(92.61deg, #1febae 27.31%, #ffed49 140.06%),
    linear-gradient(0deg, #1febae, #1febae);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  fill: url(#paint0_linear);
`;

export const ArchiveLinks: FC = () => {
  return (
    <div>
      <Row>
        <Col startMD={0} endMD={6} startSM={0} endSM={12}>
          <a href="https://praguedatacongress.com/" rel="noreferrer">
            <RotatedButton>
              <ArrowIcon />
            </RotatedButton>
          </a >
          <PreviousYear href="/archive/2021" rel="noreferrer">
            Archive
          </PreviousYear>
        </Col>
        <Col startMD={7} endMD={12} startSM={1} endSM={12}>
          <LinksBox>
            <NavLinks margin={24}>
              <li>
                <GradientA href="/archive/2021" rel="noreferrer">
                  2021
                </GradientA>
              </li>
              <li>
                <GradientA href="/archive/2020" rel="noreferrer">
                  2020
                </GradientA>
              </li>
              <li>
                <ActiveYear href="/archive/2019" rel="noreferrer">
                  2019
                </ActiveYear>
              </li>
            </NavLinks>
          </LinksBox>
        </Col>
      </Row>
    </div>
  );
};
