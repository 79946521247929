import { ComponentPropsWithoutRef, FC } from "react";
import styled from "styled-components";

const ImageWrapper = styled.div`
  position: relative;
`;

const StyledImage = styled.img<{ fullWidth?: boolean }>`
  max-width: 100%;
  ${({ fullWidth }) => (fullWidth ? "width: 100%;" : "")}
  filter: grayscale(1);
  display: block;
`;

const ImageOverlayColor = styled.div`
  position: absolute;
  inset: 0;
  background-color: #171e6566;
`;

export const TintedImage: FC<
  Omit<ComponentPropsWithoutRef<"img">, "children"> & { fullWidth?: boolean }
> = ({ className, ...props }) => {
  return (
    <ImageWrapper className={className}>
      <StyledImage {...props} />
      <ImageOverlayColor />
    </ImageWrapper>
  );
};
