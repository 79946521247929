import { Speaker } from "./speaker";

export enum TopicType {
  normal = "normal",
  workshop = "workshop",
  break = "break",
  afterparty = "afterparty",
}

export interface TopicTag {
  tag: string;
}

export interface Topic {
  id: number;
  title: string;
  speakers: Speaker[];
  timeFrom: string;
  timeTo: string;
  description: string;
  type: TopicType;
  event_topic_tags: TopicTag[];
}

export interface EventDay {
  topics: Topic[];
  date: string;
}

export interface Program {
  eventDays: EventDay[];
  year: number;
}
