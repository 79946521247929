import { FC } from "react";
import { createGlobalStyle } from "styled-components";
import { queries } from "utils/queries";
import { ReactComponent as Gradient } from "./gradient.svg";

const Styles = createGlobalStyle`
  :root {
    --error-color: #D8000C;
  }

  body {
    font-family: Helvetica, sans-serif;
    font-size: 16px;
    color: #ffffff;
    background: linear-gradient(179.21deg, #182066 -2.06%, #0E0858 86.52%);
    text-shadow: 0px 2px 36px rgba(0, 0, 0, 0.2);
  }

  h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    text-transform: uppercase;

    font-size: 40px;
    line-height: 48px;
    
    ${queries.md} {
      font-size: 72px;
      line-height: 72px;
    }

    ${queries.lg} {
      font-size: 88px;
      line-height: 88px;
    }
  }

  h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    
    ${queries.md} {
      font-size: 48px;
      line-height: 48px;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  button {
    cursor: pointer;
  }

  a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  }

  select:hover, label[for]:hover {
    background: linear-gradient(92.61deg, #1febae 27.31%, #ffed49 140.06%), linear-gradient(0deg, #1febae, #1febae);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    fill: url(#paint0_linear);
  }
`;

export const GlobalStyles: FC = () => {
  return (
    <>
      <Styles />
      <Gradient />
    </>
  );
};
