import styled from "styled-components";

export const UnderlinedText = styled.span`
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  text-decoration: underline;

  a:hover & {
    color: #1febaf;
  }
`;
