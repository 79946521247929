import { Col } from "components/Col";
import { GradientLink } from "components/GradientLink";
import { Row } from "components/Row";
import { TextWithArrowIcon } from "components/TextWithArrowIcon";
import { UnderlinedText } from "components/UnderlinedText";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Speaker } from "types/speaker";
import dataSpeakers from "../../../../data/speakers.json";

const Container = styled.div`
  background-color: #0e0a4a;
  padding-top: 128px;
  padding-bottom: 174px;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 40px;
  line-height: 64px;
`;

const FullWidthUnderlinedText = styled(UnderlinedText)`
  width: 100%;
`;

const InfoText = styled.h3`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
`;

export const ProgramSection: FC = () => {
  const [speakerCount, setSpeakerCount] = useState<number | null>(null);

  useEffect(() => {
    setSpeakerCount((dataSpeakers as Speaker[]).length);
  }, []);

  return (
    <Container id="program">
      <Row>
        <Col startMD={1} endMD={11}>
          <GradientLink
            to="/program"
            onClick={() => document.body.scrollIntoView({ block: "start" })}
          >
            <TextWithArrowIcon size={24}>
              <FullWidthUnderlinedText>
                <Title>View program</Title>
              </FullWidthUnderlinedText>
            </TextWithArrowIcon>
            <InfoText>2 days{!!speakerCount && `, ${speakerCount} speakers`}</InfoText>
          </GradientLink>
        </Col>
      </Row>
    </Container>
  );
};
