import OrganizerIntro from "./audio/jqA4WBzfYgsuZyGQ.mp3";
import PragueCityDataCongressIntro from "./audio/PXGVQuglC3Vp9_41.mp3";
import MaaSampOpenData from "./audio/PXGVQuglC3Vp9_41.mp3"
import TMaaSTrafficManagementService from "./audio/0HzQLWmVS8fsiGjm.mp3"
import InteractiveSimulation from "./audio/xGCo2uhUvX01oB9g.mp3"
import PlayTheCity from "./audio/8oMwK8ke3s--gTxc.mp3"
import TheDataManagementStrategy from "./audio/TmNNXMTbkSDVPC-1.mp3"
import Freebike from "./audio/JM1i20xh4IivJ4zE.mp3"
import img1 from "./imgPodcast/img1.jpg"
import img2 from "./imgPodcast/img2.jpg"
import img3 from "./imgPodcast/img3.jpg"
import img4 from "./imgPodcast/img4.jpg"
import img5 from "./imgPodcast/img5.jpg"
import img6 from "./imgPodcast/img6.jpg"
import img7 from "./imgPodcast/img7.jpg"
import img8 from "./imgPodcast/img8.jpg"


export const dataPodcasts2020 = [
    {
      title: "Organizer Intro",
      author: "with Michal Fišer",
      img: img2,
      audio: OrganizerIntro,
    },
    {
        title: "Prague City Data Congress 2019 Intro",
        author: "with Benedikt Kotmel",
        img: img1,
        audio: PragueCityDataCongressIntro,
      },
      {
        title: "MaaS &amp; Open Data : How Will Cities and Operators Move Forward?",
        author: "with Ross Douglas",
        img:  img3,
        audio: MaaSampOpenData,
      },
      {
        title: "TMaaS: Traffic Management as a Service",
        author: "with Evelien Marlier",
        img: img4,
        audio: TMaaSTrafficManagementService,
      },
      {
        title: "Interactive Simulation: Novel interfaces for collaborative decision-making",
        author: "with Ira Winder",
        img: img5,
        audio: InteractiveSimulation,
      },
    
      {
        title: "Play the City: Smart Community Engagement",
        author: "with Ekim Tan",
        img: img6,
        audio: PlayTheCity,
      },
    
      {
        title: "The Data Management Strategy &amp; Open Data Barcelona",
        author: "with María Jesús Calvo",
        img: img7,
        audio: TheDataManagementStrategy,
      },

      {
        title: "Freebike: Radical innovations in dockless electric bike sharing",
        author: "with Charles Butler",
        img: img8,
        audio: Freebike,
      },
      

]