import { FC } from "react";
import styled from "styled-components";
import { Section } from "components/Section";
import { Row } from "components/Row";
import { Col } from "components/Col";
import { queries } from "utils/queries";
import { DownloadIcon } from "../icons/index";
import { topicsDataPresentetion } from "./data";

const Container = styled(Section)`
  padding-top: 32px;
  padding-bottom: 32px;

  ${queries.md} {
    padding-top: ${({ paddingMD }) => (paddingMD ? paddingMD : 64)}px;
    padding-bottom: ${({ paddingMD }) => (paddingMD ? paddingMD : 64)}px;
  }

  ${queries.lg} {
    padding-top: ${({ paddingLG }) => (paddingLG ? paddingLG : 96)}px;
    padding-bottom: ${({ paddingLG }) => (paddingLG ? paddingLG : 96)}px;
  }
  ${queries.onlySM} {
  }
`;

const SpanBlock = styled.span`
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 2rem;
  line-height: 150%;
  color: #ffffff;
  ${queries.md} {
  }
  ${queries.onlySM} {
    font-size: 1.5rem;
  }
`;

const BorderBox = styled.div`
  display: grid;
  width: 100% !important;
  height: 9rem;
  border: solid;
  color: #7788c3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${queries.onlySM} {
    height: 7rem;
  }
`;

const DataAndTheCityTitle = styled.p`
  position: absolute;
  margin: 2rem 0 5rem 2rem;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 133%;
  color: #ffffff;
  ${queries.md} {
    font-size: 1.2rem;
  }
  ${queries.onlySM} {
    font-size: 0.8rem;
    margin: 2rem 0 5rem 1rem;
  }
`;
const DataAndTheCitySubTitle = styled.p`
  margin-top: 5rem;
  margin-left: 2rem;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 133%;
  color: #7788c3;

  ${queries.onlySM} {
    font-size: 1rem;
    margin-top: 4rem;
    margin-left: 1rem;
  }
`;

const Margin = styled.div`
  margin-top: 2.5rem;
  ${queries.onlySM} {
    margin-top: 1rem;
  }
`;

const RotatedDButton = styled.div`
  position: relative;
  padding-top: 4rem;
  alighn-item: center;
  width: 22px;
  height: 32.4px;
  padding-left: -10%;
  padding-top: 3rem;

  ${queries.md} {
    padding-right: 100px;
  }
  ${queries.onlySM} {
    margin-right: 50px;
  }
`;

export const PresentationsLinks: FC = () => {
  return (
    <>
      <Container id="presentations">
        <SpanBlock>Presentations</SpanBlock>
        <Margin />
        <Row>
          {topicsDataPresentetion.map(({ title, subTitle, href }, index) => (
            <Col
              key={index}
              startMD={index % 2 === 0 || index === 0 ? 0 : 6}
              endMD={index % 2 === 0 || index === 0 ? 6 : 12}
              startSM={index % 2 === 0 || index === 0 ? 0 : 0}
              endSM={index % 2 === 0 || index === 0 ? 12 : 12}
            >
              <BorderBox>
                <Row>
                  <Col startSM={0} endSM={9}>
                    <DataAndTheCityTitle>{title}</DataAndTheCityTitle>
                    <DataAndTheCitySubTitle>{subTitle}</DataAndTheCitySubTitle>
                  </Col>
                  <Col startSM={9} endSM={12}>
                    <RotatedDButton>
                      <a href={href}>
                        <DownloadIcon />
                      </a>
                    </RotatedDButton>
                  </Col>
                </Row>
              </BorderBox>
              <Margin />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};
