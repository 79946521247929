import * as yup from "yup";
import {
  AttendanceOption,
  attendanceOptions,
  organizationTypeVisitorOptions,
  organizatoinTypePressOptions,
  registerAsOptions,
} from "./registrationOptions";

const DEFAULT_MAX_LENGTH = 100;
const DEFAULT_LABEL = "This field";

const reqString = () => yup.string().label(DEFAULT_LABEL).required("This field is required");

export const registrationValidationSchema = yup.object().shape({
  name: reqString().max(DEFAULT_MAX_LENGTH),
  surname: reqString().max(DEFAULT_MAX_LENGTH),
  email: reqString().email("This is not a valid email").max(320),
  country: reqString().max(DEFAULT_MAX_LENGTH),
  attendInPerson: reqString().oneOf(attendanceOptions),
  address: yup.string().when("attendInPerson", {
    is: "In person",
    then: reqString().max(200),
  }),
  phoneNumber: yup.string().when(["attendInPerson", "registerAs"], {
    is: (attendInPerson: AttendanceOption, registerAs: typeof registerAsOptions[number]) =>
      attendInPerson === "In person" || registerAs === "Speaker",
    then: reqString().max(DEFAULT_MAX_LENGTH),
  }),
  registerAs: reqString().oneOf(registerAsOptions),
  organizationName: yup.string().when("registerAs", {
    is: "Visitor",
    then: reqString().max(DEFAULT_MAX_LENGTH),
  }),
  organizationType: yup.string().when("registerAs", {
    is: "Visitor",
    then: reqString().oneOf([...organizatoinTypePressOptions, ...organizationTypeVisitorOptions]),
  }),
  project: yup.string().when("registerAs", {
    is: "Speaker",
    then: reqString().max(DEFAULT_MAX_LENGTH),
  }),
  countryOfRealization: yup.string().when("registerAs", {
    is: "Speaker",
    then: reqString().max(DEFAULT_MAX_LENGTH),
  }),
  cityOfRealization: yup.string().when("registerAs", {
    is: "Speaker",
    then: reqString().max(DEFAULT_MAX_LENGTH),
  }),
  description: yup.string().when("registerAs", {
    is: "Speaker",
    then: reqString().max(1000),
  }),
  agreement: yup
    .boolean()
    .oneOf([true], "You must agree with the terms and conditions to register!"),
  position: reqString().max(DEFAULT_MAX_LENGTH),
  funFact: yup.string().label(DEFAULT_LABEL).max(DEFAULT_MAX_LENGTH),
});
