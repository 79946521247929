import { FC } from "react";
import styled from "styled-components";
import { Section } from "components/Section";
import { Row } from "components/Row";
import { Col } from "components/Col";
import { queries } from "utils/queries";
import ReactPlayer from "react-player";

const Container = styled(Section)`
  padding-top: 32px;
  padding-bottom: 32px;

  ${queries.md} {
    padding-top: ${({ paddingMD }) => (paddingMD ? paddingMD : 64)}px;
    padding-bottom: ${({ paddingMD }) => (paddingMD ? paddingMD : 64)}px;
  }

  ${queries.lg} {
    padding-top: ${({ paddingLG }) => (paddingLG ? paddingLG : 96)}px;
    padding-bottom: ${({ paddingLG }) => (paddingLG ? paddingLG : 96)}px;
  }
`;

const SpanBlock = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
`;

const VideoSubTitle = styled.p`
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 2rem;
  line-height: 125%;
  color: #7788c3;
  position: absolute;

  ${queries.onlySM} {
    font-size: 1.5rem;
  }
`;
const VideoBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  ${queries.onlySM} {
    padding-bottom: 20px;
  }
`;
const Wrapper = styled.div`
  position: relative;
  padding-top: 3rem;
  width: 100%;
`;

const MarginRow = styled(Row)`
  ${queries.lg} {
    margin-top: 2.5rem;
  }

  ${queries.onlySM} {
    padding-top: 10px;
    justify-content: center;
  }
`;

export const ArchiveVideo: FC = () => {
  return (
    <>
      <Container id="video">
        <SpanBlock>Videos</SpanBlock>
        <VideoSubTitle>Cities Talk</VideoSubTitle>
        <VideoBox>
          <MarginRow>
            <Col startSM={0} endSM={12} startMD={0} endMD={12}>
              <Wrapper>
                <ReactPlayer
                  height="50vw"
                  width="100%"
                  controls
                  url="https://youtu.be/xoAYPW8DmiM"
                />
              </Wrapper>
            </Col>
          </MarginRow>
        </VideoBox>
        {/* <MarginRow /> */}
      </Container>
      <Container id="video-innovation">
        <VideoSubTitle>New expressions</VideoSubTitle>
        <VideoBox>
          <MarginRow>
            <Col startMD={0} endMD={6} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="30vw"
                  controls
                  url="https://www.youtube.com/embed/26AaLAkgOys"
                />
              </Wrapper>
            </Col>
            <Col startMD={6} endMD={12} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="30vw"
                  controls
                  url="https://www.youtube.com/embed/0AuetXoRp0k"
                />
              </Wrapper>
            </Col>

            <Col startMD={0} endMD={6} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="30vw"
                  controls
                  url="https://youtu.be/sdGRnjczhOU"
                />
              </Wrapper>
            </Col>
            <Col startMD={6} endMD={12} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="30vw"
                  controls
                  url="https://youtu.be/vWiSDZJK--Q"
                />
              </Wrapper>
            </Col>
            <Col startMD={0} endMD={6} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="30vw"
                  controls
                  url="https://youtu.be/7nS6lIY_2Bo"
                />
              </Wrapper>
            </Col>
          </MarginRow>
        </VideoBox>
      </Container>
      <Container id="video-opensource">
        <VideoSubTitle>Design accessibility</VideoSubTitle>
        <VideoBox>
          <MarginRow>
            <Col startMD={0} endMD={6} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://youtu.be/dna-AQr-wcs"
                />
              </Wrapper>
            </Col>
            <Col startMD={6} endMD={12} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://youtu.be/rS_RSgFCBWQ"
                />
              </Wrapper>
            </Col>
            <Col startMD={0} endMD={6} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://youtu.be/cj8ICl5kpYc"
                />
              </Wrapper>
            </Col>
            <Col startMD={6} endMD={12} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://youtu.be/v_UTvu3tc00"
                />
              </Wrapper>
            </Col>
            <Col startMD={0} endMD={6} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://youtu.be/r5e4os18Ewo"
                />
              </Wrapper>
            </Col>
            <Col startMD={6} endMD={12} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://youtu.be/P8okuiPYo6A"
                />
              </Wrapper>
            </Col>
          </MarginRow>
        </VideoBox>
      </Container>
      <Container id="video-opensource">
        <VideoSubTitle>Data narratives</VideoSubTitle>
        <VideoBox>
          <MarginRow>
            <Col startMD={0} endMD={6} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://youtu.be/MThBEi8XjPY"
                />
              </Wrapper>
            </Col>
            <Col startMD={6} endMD={12} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://youtu.be/QCJah0hwXcg"
                />
              </Wrapper>
            </Col>
            <Col startMD={0} endMD={6} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://youtu.be/teLsqrgLkZI"
                />
              </Wrapper>
            </Col>
            <Col startMD={6} endMD={12} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://youtu.be/nwt7wnKHeAk"
                />
              </Wrapper>
            </Col>
          </MarginRow>
        </VideoBox>
      </Container>
    </>
  );
};
