import { ComponentType } from "react";
import styled from "styled-components";

export const WithGradient = (Component: ComponentType | keyof JSX.IntrinsicElements) => styled(
  Component
)`
  background: linear-gradient(92.61deg, #1febae 27.31%, #ffed49 140.06%),
    linear-gradient(0deg, #1febae, #1febae);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  fill: url(#paint0_linear);
`;
