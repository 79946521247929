import { FC } from "react";
import styled from "styled-components";
import { Col } from "./Col";
import { Row } from "./Row";

const StyledH2 = styled.h2`
  display: flex;
  align-items: center;
  padding-bottom: 48px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  margin-bottom: 120px;
`;

export const Title: FC = ({ children }) => {
  return (
    <Row>
      <Col startMD={1} endMD={11}>
        <StyledH2>{children}</StyledH2>
      </Col>
    </Row>
  );
};
