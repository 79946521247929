import { Col } from "components/Col";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { Layout } from "components/Layout";
import { Row } from "components/Row";
import { configuration } from "configuration";
import { FC, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { queries } from "utils/queries";
import { ProgramTopic } from "./ProgramTopic";
import { useProgramsData } from "hooks/useProgram";
import { Topic } from "types/programType";
import { formatTime } from "utils/timeUtils";
import { useHistory, useParams } from "react-router";

const roundMinutesUp = (time: string) => {
  const [hoursString, minutesString] = time.split(":");

  const minutes = parseInt(minutesString, 10);
  if (minutes > 30) {
    const newHours = parseInt(hoursString, 10) + 1;
    return `${newHours > 9 ? newHours : `0${newHours}`}:00:00`;
  } else if (minutes > 10) {
    return `${hoursString}:30:00`;
  }

  return time;
};

const addHour = (time: string) => {
  const [hoursString, minutesString] = time.split(":");
  const hours = parseInt(hoursString, 10) + 1;
  const minutes = parseInt(minutesString, 10);

  return `${hours > 9 ? hours : `0${hours}`}:${
    minutes <= 10 ? "00" : minutesString
  }:00`;
};

const ProgramDayButtonContainer = styled.div`
  margin-top: 56px;
`;

const ProgramDayButton = styled.button<{ active: boolean }>`
  margin-top: 16px;
  padding: 16px 32px;
  background: none;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  margin-right: 24px;
  color: #${({ active }) => (active ? "42EC9F" : "7788c3")};
  border: 1px solid #${({ active }) => (active ? "42EC9F" : "7788c3")};

  ${queries.onlySM} {
    font-size: 16px;
    padding: 8px 16px;
  }
`;

const StyledRow = styled(Row)`
  padding-bottom: 128px;
`;

const EventsContainer = styled.div`
  margin-top: 40px;
`;

const TimeCategoryContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 64px;
  }
`;

const TimeCategoryTitle = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 8px;
`;

interface TopicTimeGroup {
  from: string;
  to: string;
  topics: Topic[];
}

export const ProgramView: FC = () => {
  const { day: dayQueryString } = useParams<{ day?: string }>();
  const initialSelectedDay = dayQueryString ? parseInt(dayQueryString) || 0 : 0;
  const [selectedDay, setSelectedDay] = useState(initialSelectedDay);
  const { programs, isLoading } = useProgramsData();
  const history = useHistory();

  const program = useMemo(
    () =>
      programs?.find(
        (yearlyProgram) => yearlyProgram.year === configuration.CURRENT_YEAR
      ),
    [programs]
  );

  const selectDay = useCallback(
    (dayIndex: number) => {
      setSelectedDay(dayIndex);
      history.replace(`/program/${dayIndex}`);
    },
    [history]
  );

  const categories = useMemo(() => {
    const topics = program?.eventDays?.[selectedDay]?.topics.slice();

    if (!topics?.length) {
      return [];
    }

    const timeGroups: TopicTimeGroup[] = [];

    while (topics.length > 0) {
      const topic = topics.shift() as Topic;
      const timeGroup = timeGroups.find((group) => {
        return group.to > topic.timeFrom;
      });

      if (!timeGroup) {
        const hourAfterStart = addHour(topic.timeFrom);

        timeGroups.push({
          from: topic.timeFrom,
          to: roundMinutesUp(
            hourAfterStart > topic.timeTo ? hourAfterStart : topic.timeTo
          ),
          topics: [topic],
        });
      } else {
        timeGroup.topics.push(topic);

        if (timeGroup.to < topic.timeTo) {
          timeGroup.to = roundMinutesUp(topic.timeTo);
        }
      }
    }

    return timeGroups;
  }, [program, selectedDay]);

  return (
    <Layout header={<Header />} footer={<Footer />}>
      <StyledRow>
        <Col startMD={1} endMD={11}>
          <h2>Program</h2>
          <ProgramDayButtonContainer>
            {program?.eventDays.map((eventDay, index) => (
              <ProgramDayButton
                active={selectedDay === index}
                key={index}
                onClick={() => selectDay(index)}
              >
                {moment(eventDay.date).format()}
              </ProgramDayButton>
            ))}
          </ProgramDayButtonContainer>
          <EventsContainer>
            {isLoading && <>loading...</>}
            {!isLoading &&
              categories.map((category) => (
                <TimeCategoryContainer key={category.from}>
                  <TimeCategoryTitle>
                    {formatTime(category.from)}
                  </TimeCategoryTitle>
                  {category.topics.map((topic, index) => (
                    <ProgramTopic key={index} topic={topic} />
                  ))}
                </TimeCategoryContainer>
              ))}
          </EventsContainer>
        </Col>
      </StyledRow>
    </Layout>
  );
};
