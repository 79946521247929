import { ReactComponent as DesignAccessibilityIcon } from "./design_accessibility_icon.svg";
import { ReactComponent as NewExpressionsIcon } from "./new_expressions_icon.svg";
import { ReactComponent as DataNarrativesIcon } from "./data_narratives_icon.svg";

export const topicsData = [
  {
    title: "Design accessibility",
    content: [
      "The essence of this section lies in answering the questions of how to make data accessible and interesting to the public? We will talk about user interfaces, the language used to talk about data, data design, and constraint design.",
      "You can expect workshops and discussions with speakers sharing their personal hands-on experiences with data design.",
    ],
    Icon: DesignAccessibilityIcon,
    image: "/images/topic_design_accessibility.png",
  },
  {
    title: "New expressions",
    content: [
      "Studios and individuals will share their best know-how on new ways, tools, and tech used in data visualizations. We will touch upon tricks used to involve citizens and alternative approaches to visualizing city data and creating urban storytelling through structure and data. ",
      "You can expect an interactive workshop session on contextual data visualization to try it yourself!",
    ],
    Icon: NewExpressionsIcon,
    image: "/images/topic_new_expressions.png",
  },
  {
    title: "Data narratives",
    content: [
      "To reflect current questions in urban data storytelling, presenters from different fields will come together to co-create a fruitful debate. A discussion with city officials reflecting on their struggles will be accompanied by a more in-depth look into city digitalization, AI, and data journalism by practitioners.",
      "The workshops will focus on forming a better open data narrative as public servants and better mobility planning. ",
    ],
    Icon: DataNarrativesIcon,
    image: "/images/topic_data_narratives.png",
  },
];
