import { FC, Fragment, useState } from "react";
import styled from "styled-components";
import { Col } from "components/Col";
import { Row } from "components/Row";
import { RelativeSection } from "components/Section";
import { TextContainer } from "components/TextContainer";
import { Tabs } from "components/Tabs";
import { Topic, TopicButton } from "./Topic";
import { topicsData } from "./data";
import { queries } from "utils/queries";
import { LeftWave1, RightWave1, LeftWave2 } from "components/Waves";
import { GradientA } from "components/GradientLink";

const UnderlinedLink = styled(GradientA)`
  text-decoration: underline;

  &:hover {
    color: #1febaf;
  }
`;

const SpanBlock = styled.span`
  display: block;
`;

const MarginRow = styled(Row)`
  margin-top: 128px;
`;

const List = styled.ul`
  list-style: none;

  ${queries.onlySM} {
    display: none;
  }
`;

const TopicOnlySM = styled(Topic)`
  ${queries.md} {
    & {
      display: none;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: 24px;

  ${queries.md} {
    margin-bottom: 64px;
  }
`;

const TitleWithMargin = styled.h2`
  ${queries.onlySM} {
    margin-bottom: 32px;
  }
`;

export const About: FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (
    <RelativeSection id="about" paddingMD={192} paddingLG={160}>
      <RightWave1 />
      <LeftWave1 />
      <LeftWave2 />
      <Row>
        <Col startMD={1} endMD={5}>
          <TitleWithMargin>
            <SpanBlock>About</SpanBlock>
            <SpanBlock>the congress</SpanBlock>
          </TitleWithMargin>
        </Col>
        <Col startMD={5} endMD={11}>
          <TextContainer>
            <p>
              Prague City Data Congress is organized by the municipal company Operátor ICT, which is
              responsible for Smart City solutions. The aim of the congress is to showcase studies
              and drive networking in the fields of data, urban planning, and municipal
              coordination.
            </p>
            <p>
              In the 2021 edition, defined by the times of altered balance and changing perceptions,
              PCDC will focus on accessible design, data visualizations, and data-based
              storytelling. Together with leading experts, we will look beyond the numbers and
              discover the role that city data plays in our new reality!
            </p>
            <p>
              <strong>
                <UnderlinedLink href="#registration">Book your seat for free</UnderlinedLink> if you
                want to attend this event. You can participate both in person and online, but
                booking is essential either way due to Covid-19 restrictions.
              </strong>
            </p>
            <p>
              <strong>
                If you are interested in active participation, get back to us at{" "}
                <UnderlinedLink href="mailto:pcdc@operatorict.cz">
                  pcdc@operatorict.cz
                </UnderlinedLink>
                .
              </strong>
            </p>
          </TextContainer>
        </Col>
      </Row>
      <MarginRow>
        <Col startMD={1} endMD={4} endLG={3}>
          <List>
            {topicsData.map(({ title, Icon }, index) => (
              <li key={index}>
                <TopicButton
                  Icon={Icon}
                  onClick={setSelectedTabIndex}
                  isActive={selectedTabIndex === index}
                  topicIndex={index}
                >
                  {title}
                </TopicButton>
              </li>
            ))}
          </List>
        </Col>
        <Col startMD={5} endMD={11}>
          <Tabs
            selectedTabIndex={selectedTabIndex}
            tabs={topicsData.map(({ content, title, Icon, image }, index) => (
              <Fragment key={index}>
                <TopicOnlySM Icon={Icon}>{title}</TopicOnlySM>
                <Image src={image} alt={title} />
                <TextContainer>
                  {content.map((text, index) => (
                    <p key={index}>{text}</p>
                  ))}
                </TextContainer>
              </Fragment>
            ))}
          />
        </Col>
      </MarginRow>
    </RelativeSection>
  );
};
