import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { PlayIcon } from "./imgPodcast/icons";
import { StopIcon } from "./imgPodcast/icons";
import { queries } from "utils/queries";
import "./style.css";

const ContainerPodcast = styled.div`
  display: grid;
  grid-template-columns: 15ch auto;
  background: linear-gradient(103.8deg, #161c64 -0.21%, #2a2c7e 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 9.5rem;
  max-width: 2560px;
`;

const ImgPodcast = styled.img`
  overflow: hidden;
  background: #c04848;
  background: linear-gradient(rgb(72, 0, 72, 0.8), rgb(192, 72, 72, 0.8)),
  background-size: cover;
  background-repeat: no-repeat;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
  filter: invert
`;

const BoxPodcast = styled.div`
  ${queries.onlySM} {
    font-size: 12px;
  }
`;

const TitlePodcast = styled.h3`
  display: grid;
  font-size: 16px;
  margin-top: 1rem;
  padding-right: 5rem;
  position: relative;
  overflow: hidden;

  ${queries.onlySM} {
  }
`;

const SubTitlePodcast = styled.div`
  position: relative;
  font-family: "Helvetica";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 0.5rem;
`;
const WaveformBlock = styled.div`
  display: flex;
  position: relative;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  padding-right: 1.5rem;
  overflow: hidden;
`;
const Waveform = styled.div`
  max-width: 760px;
  display: block;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
  width: 100%;
  position: relative;

  ${queries.md} {
    width: 100%;
  }
`;

const PlayIconStyle = styled(PlayIcon)`
  position: relative;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
  cursor: pointer;
  margin-top: 9px;
`;
const PauseStyle = styled(StopIcon)`
  margin-right: 7px;
`;
const RectangleStyle = styled.div`
  display: inline-grid;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 52px;
  background: #42ec9f;
  font-family: "Helvetica";
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #171c64;
  margin-top: 7px;
`;

const formWaveSurferOptions = (ref: any) => ({
  container: ref,
  progressColor:
    "linear-gradient(92.61deg, #1FEBAE 27.31%, #FFED49 140.06%), #1FEBAE",
  cursorColor:
    "background: linear-gradient(92.61deg, #1FEBAE 27.31%, #FFED49 140.06%), #1FEBAE;",
  barWidth: 2,
  barRadius: 3,
  responsive: true,
  height: 40,
  normalize: true,
  //maxCanvasWidth: 100,
  //partialRender: true,
  waveColor: "#1FEBAE ",
  hideScrollbar: true,
  cursorWidth: 1,
  barGap: 3,
  pixelRatio: 5, //5
  barMinHeight: 100,
});

export function WaveFormComponent(props: any) {
  let { title, author, img, audio } = props.waveSurfParams;
  const waveformRef = useRef(null as any);
  const wavesurfer = useRef(null as any);
  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [duration, setDuration] = useState("00:00");
  const [currentPlayTime, setCurrentPlayTime] = useState("00:00");

  useEffect(() => {
    async function init() {
      const { default: WaveSurfer } = await import("wavesurfer.js");

      setPlay(false);

      const options = formWaveSurferOptions(waveformRef.current) as any;
      wavesurfer.current = WaveSurfer.create(options);
      wavesurfer.current.load(audio);
      wavesurfer.current.on("ready", function () {
        setDuration(secondsToTimestamp(wavesurfer.current.getDuration()));

        if (wavesurfer.current) {
          wavesurfer.current.setVolume(volume);
          setVolume(volume);
          console.log(wavesurfer.current);
        }
      });

      wavesurfer.current.on("audioprocess", function () {
        if (wavesurfer.current.isPlaying()) {
          let currentTime = wavesurfer.current.getCurrentTime();
          setCurrentPlayTime(secondsToTimestamp(currentTime));
        }
      });

      const secondsToTimestamp = function (seconds: any) {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        let timeString = h ? h + ":" : "";
        timeString += m < 10 ? "0" + m : m;
        timeString += ":";
        timeString += s < 10 ? "0" + s : s;
        return timeString;
      };
    }

    init();

    return () => wavesurfer.current.destroy();
  }, []);

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  return (
    <div>
      <ContainerPodcast id="podcast">
        <ImgPodcast id="imgpodcast" src={img} />
        <BoxPodcast id="boxpodcast">
          <TitlePodcast id="title">{title}</TitlePodcast>
          <WaveformBlock id="waveformblock">
            <a onClick={handlePlayPause}>
              {!playing ? <PlayIconStyle /> : <PauseStyle />}
            </a>
            <RectangleStyle id="rectangle">{currentPlayTime}</RectangleStyle>
            <div>
              <Waveform
                className="waveform"
                id="waveform"
                ref={waveformRef}
                style={{ height: "20px" }} //width: "20vw"
              />
            </div>
            <RectangleStyle id="rectangle">{duration}</RectangleStyle>
          </WaveformBlock>
          <SubTitlePodcast id="subtitle">{author}</SubTitlePodcast>
        </BoxPodcast>
      </ContainerPodcast>
    </div>
  );
}
