import { Col } from "components/Col";
import { CookieConsent } from "components/CookieConsent";
import { Row } from "components/Row";
import { FC } from "react";
import styled from "styled-components";
import { queries } from "utils/queries";
import { FooterCopyright } from "./FooterCopyright";
import { FooterPreviousYears } from "./FooterPreviousYears";
import { FooterSocials } from "./FooterSocials";

const StyledFooter = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 32px;

  ${queries.md} {
    padding: 40px;
  }

  ${queries.onlySM} {
    flex-direction: column;
  }
`;

const RowWithBackground = styled(Row)`
  background: #0e0a4a;
`;

export const Footer: FC = () => {
  return (
    <RowWithBackground>
      <Col startMD={1} endMD={11}>
        <StyledFooter>
          <FooterSocials />
          <CookieConsent />
          <FooterCopyright />
        </StyledFooter>
      </Col>
    </RowWithBackground>
  );
};
