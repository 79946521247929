import { GradientA } from "components/GradientLink";
import { FC, MouseEvent as ReactMouseEvent, useCallback } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { queries } from "utils/queries";
import { links } from "./links";

const List = styled.ul`
  text-align: right;
  list-style: none;
  margin: 0;
  padding: 0;

  & li {
    display: inline-block;

    &:not(:first-child) {
      margin-left: 72px;
    }
  }

  & a {
    line-height: 40px;
  }

  ${queries.onlySM} {
    display: none;
  }
`;

export const InlineNavigation: FC = () => {
  const { pathname } = useLocation();

  const onNavLinkClick = useCallback(
    (e: ReactMouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {
      if (link.substring(0, 2) === "/#" && pathname === "/") {
        try {
          const element = document.querySelector(link.substring(1));

          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
            e.preventDefault();
          }
        } finally {
        }
      }
    },
    [pathname]
  );

  return (
    <List>
      {links.map(({ label, link, external }, index) => (
        <li key={index}>
          <GradientA
            href={link}
            onClick={external ? undefined : (e) => onNavLinkClick(e, link)}
            target={external ? "_blank" : undefined}
            rel={external ? "noreferrer" : undefined}
          >
            {label}
          </GradientA>
        </li>
      ))}
    </List>
  );
};
