import styled from "styled-components";

export const UnderlinedLinkWrapper = styled.p`
  font-family: "Montserrat", sans-serif;

  & a {
    text-decoration: underline;
  }

  & a:hover {
    color: rgb(31, 235, 175);
  }
`;
