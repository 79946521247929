import { configuration } from "configuration";

export const links = [
  { label: "About", link: "/#about", external: false },
  // { label: "2021 Presentations", link: configuration.PRESENTATIONS_URL, external: true },
  { label: "Program", link: "/program" , external: false},
  { label: "Speakers", link: "/#speakers",external: false },
  ...(configuration.SHOW_REGISTRATION ? [{ label: "Registration", link: "/#registration" }] : []),
  { label: "Venue", link: "/#venue",external: false },
  { label: "Archive", link: "/archive/2021", external: false },
];
