import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Col } from "components/Col";
import { Row } from "components/Row";
import { Section } from "components/Section";
import styled from "styled-components";
import { TextInput } from "components/TextInput";
import { Formik } from "formik";
import { PrimaryButton } from "components/Buttons";
import { TextWithArrowIcon } from "components/TextWithArrowIcon";
import { CountryPicker } from "components/CountryPicker";
import { SelectInput } from "components/SelectInput";
import { AgreementCheckbox } from "./AgreementCheckbox";
import { registrationValidationSchema } from "./registrationValidationSchema";
import {
  organizationTypeVisitorOptions,
  organizatoinTypePressOptions,
  RegisterAsOption,
  registerAsOptions,
  AttendanceOption,
  attendanceOptions,
} from "./registrationOptions";
import { ErrorMessage } from "components/ErrorMessage";
import { configuration } from "configuration";
import { TextContainer } from "components/TextContainer";

const Title = styled.h2`
  margin-bottom: 40px;
`;

interface FormData {
  name: string;
  surname: string;
  email: string;
  country: string;
  attendInPerson: "" | AttendanceOption;
  address: string;
  phoneNumber: string;
  registerAs: "" | RegisterAsOption;
  organizationName: string;
  organizationType: string;
  project: string;
  countryOfRealization: string;
  cityOfRealization: string;
  description: string;
  agreement: boolean;
  position: string;
  funFact: string;
}

type PostData = Omit<FormData, "attendInPerson"> & { attendInPerson: boolean };

const initialValues: FormData = {
  name: "",
  surname: "",
  email: "",
  country: "",
  attendInPerson: "",
  address: "",
  phoneNumber: "",
  registerAs: "",
  organizationName: "",
  organizationType: "",
  project: "",
  countryOfRealization: "",
  cityOfRealization: "",
  description: "",
  agreement: false,
  position: "",
  funFact: "",
};

const getDataToSend = (data: FormData) => {
  const propertiesToSend: Array<keyof FormData> = [
    "name",
    "surname",
    "email",
    "country",
    "registerAs",
    "position",
    "funFact",
  ];

  if (data.attendInPerson === "In person") {
    propertiesToSend.push("address");
  }

  if (data.attendInPerson === "In person" || data.registerAs === "Speaker") {
    propertiesToSend.push("phoneNumber");
  }

  if (data.registerAs === "Visitor" || data.registerAs === "Press") {
    propertiesToSend.push("organizationName");
    propertiesToSend.push("organizationType");
  }

  if (data.registerAs === "Speaker") {
    propertiesToSend.push("project");
    propertiesToSend.push("cityOfRealization");
    propertiesToSend.push("countryOfRealization");
    propertiesToSend.push("description");
  }

  let dataToSend: Partial<PostData> = { attendInPerson: data.attendInPerson === "In person" };
  propertiesToSend.forEach((property) => {
    dataToSend = { ...dataToSend, [property]: data[property] };
  });

  return dataToSend;
};

const registrationUrl = `${configuration.API_URL}/registered-users`;

export const Registration: FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [hasFailed, setFailed] = useState(false);
  const [isSent, setSent] = useState(false);
  const anchor = useRef<HTMLDivElement>(null);

  const handleSubmit = useCallback((formData: FormData) => {
    const data = getDataToSend(formData);

    setFailed(false);
    setLoading(true);
    fetch(registrationUrl, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
      },
    })
      .then(() => {
        setSent(true);
      })
      .catch(() => {
        setFailed(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (isSent && anchor.current) {
      anchor.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [isSent]);

  return (
    <Section id="registration" ref={anchor}>
      <Row>
        <Col startMD={1} endMD={4} endLG={3}>
          <Title>Register &amp; take your seat</Title>
        </Col>
        <Col startMD={5} endMD={11}>
          {!isSent && (
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={registrationValidationSchema}
            >
              {({ handleSubmit, values }) => (
                <form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
                  <TextInput<FormData> label="Name" name="name" />
                  <TextInput<FormData> label="Surname" name="surname" />
                  <TextInput<FormData> label="E-mail" type="email" name="email" />
                  <CountryPicker<FormData> label="Choose country" name="country" />

                  <SelectInput<FormData>
                    label="Choose attendance"
                    name="attendInPerson"
                    options={attendanceOptions}
                  />

                  <SelectInput<FormData>
                    label="Register as"
                    name="registerAs"
                    options={registerAsOptions}
                  />

                  {values.attendInPerson === "In person" && (
                    <TextInput<FormData> label="Address" name="address" />
                  )}
                  {(values.attendInPerson === "In person" || values.registerAs === "Speaker") && (
                    <TextInput<FormData> label="Phone number" name="phoneNumber" />
                  )}

                  {(values.registerAs === "Visitor" || values.registerAs === "Press") && (
                    <>
                      <TextInput<FormData> label="Organization name" name="organizationName" />
                      <SelectInput<FormData>
                        label="Organization type"
                        name="organizationType"
                        options={
                          values.registerAs === "Press"
                            ? organizatoinTypePressOptions
                            : organizationTypeVisitorOptions
                        }
                      />
                    </>
                  )}

                  {values.registerAs === "Speaker" && (
                    <TextInput<FormData> label="Project" name="project" />
                  )}

                  {values.registerAs !== "" && (
                    <TextInput<FormData> label="Position" name="position" />
                  )}

                  {values.registerAs === "Speaker" && (
                    <>
                      <TextInput<FormData>
                        label="Country of realization"
                        name="countryOfRealization"
                      />
                      <TextInput<FormData> label="City of realization" name="cityOfRealization" />
                      <TextInput<FormData> label="Description" name="description" />
                    </>
                  )}

                  <TextInput<FormData> label="Fun fact about yourself" name="funFact" />

                  <AgreementCheckbox<FormData>
                    name="agreement"
                    attendInPerson={values.attendInPerson === "In person"}
                  />

                  <PrimaryButton disabled={isLoading} type="submit">
                    <TextWithArrowIcon>Book your seat</TextWithArrowIcon>
                    {hasFailed && <ErrorMessage>Registration failed :(</ErrorMessage>}
                  </PrimaryButton>
                </form>
              )}
            </Formik>
          )}
          {isSent && (
            <TextContainer>
              <p>
                You have successfully registered for our event and we look forward to your
                attendance! A confirmation e‑mail with further details will be sent shortly.
              </p>
            </TextContainer>
          )}
        </Col>
      </Row>
    </Section>
  );
};
