import { Col } from "components/Col";
import { GradientLink } from "components/GradientLink";
import { Row } from "components/Row";
import { Section } from "components/Section";
import { Speakers } from "components/Speakers";
import { TextWithArrowIcon } from "components/TextWithArrowIcon";
import { UnderlinedText } from "components/UnderlinedText";
import { useViewportWidth } from "hooks/useViewportWidth";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";

const MarginRow = styled(Row)`
  margin-top: 56px;
`;

const Container = styled(Section)`
  padding-top: 0;
  margin-top: -64px;
`;

const getSpeakerCount = (viewportWidth: number) => {
  if (viewportWidth <= 1338) {
    return 6;
  } else if (viewportWidth <= 1676) {
    return 4;
  } else {
    return 5;
  }
};

export const SpeakersSection: FC = () => {
  const { viewportWidth } = useViewportWidth();
  const [speakerCount, setSpeakerCount] = useState(getSpeakerCount(viewportWidth));

  useEffect(() => {
    const newCount = getSpeakerCount(viewportWidth);
    if (newCount !== speakerCount) {
      setSpeakerCount(newCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportWidth]);

  return (
    <Container id="speakers">
      <Speakers count={speakerCount} />
      <MarginRow>
        <Col startMD={1} endMD={11}>
          <GradientLink to="/speakers">
            <TextWithArrowIcon size={24}>
              <UnderlinedText>Show me more speakers</UnderlinedText>
            </TextWithArrowIcon>
          </GradientLink>
        </Col>
      </MarginRow>
    </Container>
  );
};
