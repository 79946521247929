import { ConditionalWrapper } from "components/ConditionalWrapper";
import { TintedImage } from "components/TintedImage";
import { UnderlinedLinkWrapper } from "components/UnderlinedLinkWrapper";
import { FC, Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Speaker } from "types/speaker";
import { ReactComponent as CompanyIcon } from "./companyIcon.svg";
import { GradientA } from "components/GradientLink";
import { SecondaryButton } from "components/Buttons";

const Container = styled.div`
  background: rgba(255, 255, 255, 0.3);
  max-width: 400px;
  display: flex;
  flex-direction: column;
`;

const SpeakerImage = styled(TintedImage)`
  width: 100%;
`;

const SpeakerName = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
`;

const Subtitle = styled.p`
  color: rgba(255, 255, 255, 0.6);
`;

const InfoContainer = styled.div`
  padding: 40px 32px 32px 32px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  & p:not(:first-child) {
    margin-top: 24px;
  }
`;

const SpeakerDescription = styled.p`
  font-weight: normal;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  width: 100%;
  margin-top: 32px;
`;

const LinkWrapper = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const CompanyLinkStyled = styled(GradientA)`
  display: flex;
  align-items: center;

  & svg {
    fill: #fff;
    margin-right: 8px;
  }
`;

const CompanyLink: FC<{ url: string }> = ({ url, children }) => (
  <UnderlinedLinkWrapper>
    <CompanyLinkStyled href={url} target="_blank" rel="noreferrer">
      <CompanyIcon />
      {children}
    </CompanyLinkStyled>
  </UnderlinedLinkWrapper>
);

export const EventSpeaker: FC<{ speaker: Speaker; isLink: boolean }> = ({ speaker, isLink }) => {
  const name = speaker.name?.split(" ")?.[0]?.replace("-", "‑") || "speaker";

  return (
    <Container>
      {!!speaker.picture?.url && (
        <SpeakerImage src={speaker.picture.url} alt={speaker.name} fullWidth={true} />
      )}
      <InfoContainer>
        <div>
          <SpeakerName>{speaker.name}</SpeakerName>
          <Subtitle>{speaker.title}</Subtitle>
          {!!speaker.company && (
            <ConditionalWrapper
              condition={!!speaker.companyUrl}
              PositiveWrapper={CompanyLink}
              positiveProps={{ url: speaker.companyUrl as string }}
              NegativeWrapper={Fragment}
              negativeProps={{}}
            >
              {speaker.company}
            </ConditionalWrapper>
          )}
          <SpeakerDescription>{speaker.description}</SpeakerDescription>
        </div>
        <LinkWrapper>
          {isLink && (
            <Link to={`/speaker/${speaker.id}`}>
              <StyledSecondaryButton>
                <span>More about {name}</span>
              </StyledSecondaryButton>
            </Link>
          )}
        </LinkWrapper>
      </InfoContainer>
    </Container>
  );
};
