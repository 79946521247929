import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const styles = css`
  &:hover,
  &:hover > *,
  &:hover svg {
    background: linear-gradient(92.61deg, #1febae 27.31%, #ffed49 140.06%),
      linear-gradient(0deg, #1febae, #1febae);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    fill: url(#paint0_linear);
  }
`;

export const GradientA = styled.a`
  ${styles}
`;

export const GradientLink = styled(Link)`
  ${styles}
`;
