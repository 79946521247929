import { GradientLink } from "components/GradientLink";
import { FC } from "react";
import { useLocation } from "react-router-dom";

const Logo: FC = () => <img src="/images/pcdc_logo.png" alt="PCDC logo" />;

export const HeaderLogo: FC = () => {
  const { pathname } = useLocation();

  if (pathname === "/") {
    return <Logo />;
  }

  return (
    <GradientLink to="/">
      <Logo />
    </GradientLink>
  );
};
