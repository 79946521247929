import { SmallerTitle } from "components/SmallerTitle";
import { ComponentType, FC, SVGProps } from "react";
import styled from "styled-components";
import { queries } from "utils/queries";

const IconWrapper = styled.div`
  line-height: 0;

  & svg {
    margin-right: 16px;
  }
`;

const TopicTitle = styled(SmallerTitle)`
  align-items: center;
  text-align: left;

  & svg {
    fill: url(#paint0_linear);
  }

  ${queries.onlySM} {
    display: flex;
    line-height: 20px;

    &,
    & span {
      color: transparent;
      background: linear-gradient(92.61deg, #1febae 27.31%, #ffed49 140.06%),
        linear-gradient(0deg, #1febae, #1febae);
      -webkit-background-clip: text;
      background-clip: text;
    }

    div:not(:first-child) > & {
      margin-top: 48px;
    }
  }
`;

const TextButton = styled.button<{ isActive?: boolean }>`
  position: relative;
  border: none;
  border-left: 2px solid#7788C3;
  padding: 40px 0;

  &,
  & span {
    background: #7788c3;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }

  ${({ isActive }) => (isActive ? "&, & span," : "")}
  &:hover, &:hover span {
    background: linear-gradient(92.61deg, #1febae 27.31%, #ffed49 140.06%),
      linear-gradient(0deg, #1febae, #1febae);
    -webkit-background-clip: text;
    background-clip: text;
  }

  & h2 {
    display: flex;
  }

  &::before {
    content: ${({ isActive }) => (isActive ? '""' : "none")};
    position: absolute;
    inset: 0;
    border-left: 4px solid #1febae;
    margin-left: -3px;
  }

  & svg {
    margin-left: 40px;
    margin-right: 32px;
    fill: ${({ isActive }) => (isActive ? "url(#paint0_linear)" : "#7788C3")};
  }

  &:hover svg {
    fill: url(#paint0_linear);
  }
`;

export const Topic: FC<{ Icon: ComponentType<SVGProps<SVGSVGElement>>; className?: string }> = ({
  children,
  Icon,
  className,
}) => {
  return (
    <TopicTitle className={className}>
      <IconWrapper>
        <Icon width={40} />
      </IconWrapper>
      <span>{children}</span>
    </TopicTitle>
  );
};

export const TopicButton: FC<{
  Icon: ComponentType<SVGProps<SVGSVGElement>>;
  topicIndex: number;
  onClick: (index: number) => void;
  isActive: boolean;
}> = ({ children, Icon, topicIndex, onClick, isActive }) => {
  return (
    <TextButton onClick={() => onClick(topicIndex)} isActive={isActive} type="button">
      <Topic Icon={Icon}>{children}</Topic>
    </TextButton>
  );
};
