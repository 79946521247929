import { FilterKeysByValueType } from "types/filterKeysByValueType";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { InputProps } from "types/InputProps";
import { InputContainer } from "components/InputContainer";
import { ErrorMessage } from "components/ErrorMessage";
import { ReactComponent as ChevronIcon } from "./chevron.svg";
import { useEffect } from "react";

type Props<Name> = InputProps<Name> & {
  options: string[];
};

const SelectContainer = styled.div`
  position: relative;
`;

const StyledChevron = styled(ChevronIcon)`
  fill: #fff;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  *:hover > & {
    fill: url(#paint0_linear);
  }
`;

const StyledInput = styled.select<{ isSet: boolean }>`
  background: none;
  border: none;
  width: 100%;
  color: ${({ isSet }) => (isSet ? "#fff" : "#7788c3")};
  font-size: 24px;
  height: 56px;
  appearance: none;

  &:focus {
    outline: none;
  }
`;

const StyledOption = styled.option`
  background: #fff;
  color: #0e0a4a;
  font-size: 24px;
`;

export const SelectInput = <FormData extends {}>({
  label,
  name,
  options,
}: Props<string & FilterKeysByValueType<FormData, string>>) => {
  const { handleBlur, handleChange, values, validationSchema, errors, touched, setFieldValue } =
    useFormikContext<FormData>();

  const required = validationSchema?.fields?.[name]?.exclusiveTests?.required === true;
  const isTouched = touched?.[name];
  const errorMessage = errors?.[name];
  const value = values[name] as unknown as string;

  useEffect(() => {
    if (!options.includes(value)) {
      setFieldValue(name, "");
    }
  }, [options, value, setFieldValue, name]);

  return (
    <InputContainer>
      <SelectContainer>
        <StyledInput
          value={value}
          name={name}
          required={required}
          onChange={handleChange}
          onBlur={handleBlur}
          isSet={!!value}
        >
          <StyledOption value="" disabled hidden>{`${label}${required ? " *" : ""}`}</StyledOption>
          {options.map((option) => (
            <StyledOption key={option} value={option}>
              {option}
            </StyledOption>
          ))}
        </StyledInput>
        <StyledChevron />
      </SelectContainer>
      <ErrorMessage>{!!isTouched && errorMessage}</ErrorMessage>
    </InputContainer>
  );
};
