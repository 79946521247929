const MD = 1091;
const LG = 1440;
//const LG = 1600;
//const MD = 960;


export const breakpoints = {
  smMax: `${MD - 1}px`,
  md: `${MD}px`,
  mdMax: `${LG - 1}px`,
  lg: `${LG}px`,
};
