import styled, { css } from "styled-components";
import { queries } from "utils/queries";

const sharedButtonStyles = css`
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  border-radius: 52px;

  ${queries.onlySM} {
    width: 100%;
  }

  &:hover,
  &:hover * {
    -webkit-background-clip: border-box;
    background-clip: border-box;
    color: #130f4d;
    fill: #130f4d;
    background: #fff;
  }

  &:hover * {
    background: none;
  }
`;

export const SecondaryButton = styled.button`
  ${sharedButtonStyles}

  font-size: 16px;
  padding: 12px 32px;
  line-height: 24px;
  border: 2px solid #1febae;
  color: #1febae;
  background-color: transparent;

  &:hover {
    border-color: transparent;
  }
`;

export const PrimaryButton = styled.button`
  ${sharedButtonStyles}

  background: linear-gradient(92.61deg, #1febae 27.31%, #ffed49 140.06%), #1febae;
  line-height: 40px;
  font-size: 20px;
  color: #130f4d;
  border: none;
  padding: 16px 32px;

  ${queries.md} {
    font-size: 24px;
  }

  & * {
    fill: #130f4d;
  }
`;
