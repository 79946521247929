import { FC } from "react";
import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { Partners } from "./sections/Partners";
import { Registration } from "./sections/Registration";
import { Feedback } from "./sections/Feedback";
import { About } from "./sections/About";
import { ComingSoon } from "./sections/ComingSoon";
import { Intro } from "./sections/Intro";
import { Location } from "./sections/Location";
import { SpeakersSection } from "./sections/SpeakersSection";
import { Layout } from "components/Layout";
import { ContactSection } from "./sections/Contact";
import { ProgramSection } from "./sections/ProgramSection";
import { configuration } from "configuration";

export const MainView2021: FC = () => {
  return (
    <Layout header={<Header />} footer={<Footer />}>
      <Intro />
      <About />
      <ProgramSection />
      <SpeakersSection />
      <ComingSoon />
      <Feedback />
      {!!configuration.SHOW_REGISTRATION && <Registration />}
      <Location />
      <Partners />
      <ContactSection />
    </Layout>
  );
};
