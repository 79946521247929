import { Col } from "components/Col";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { Layout } from "components/Layout";
import { Row } from "components/Row";
import { useProgramsData } from "hooks/useProgram";
import { FC, Fragment, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router";
import styled from "styled-components";
import { Topic } from "types/programType";
import { Speaker } from "types/speaker";
import moment from "moment";
import { formatTime } from "utils/timeUtils";
import { queries } from "utils/queries";
import { EventSpeaker } from "./EventSpeaker";
import { Link } from "react-router-dom";
import { UnderlinedLinkWrapper } from "components/UnderlinedLinkWrapper";
import dataSpeakers from "../../data/speakers.json";

const TimeText = styled.div`
  border: 1px solid #1febae;
  padding: 16px 32px;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  margin-top: 40px;
  margin-bottom: 32px;
  display: inline-block;

  & > * {
    display: inline-block;
  }

  & > *:not(:last-child) {
    padding-right: 24px;
    margin-right: 24px;
    border-right: 1px solid #fff;
  }
`;

const TopicContainer = styled.div`
  margin-bottom: 96px;

  ${queries.onlySM} {
    margin-bottom: 64px;
  }
`;

const ParticipantsInfo = styled.p`
  margin-bottom: 0;
  margin-top: 32px;
  line-height: 32px;
`;

const SpeakerContainer = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
  width: 100%;
  column-gap: 32px;
  row-gap: 64px;
`;

const StyledRow = styled(Row)`
  padding-bottom: 128px;
`;

const EventDescription = styled.p`
  white-space: pre-line;
`;

export const EventView: FC = () => {
  const { eventId, speakerId } = useParams<{ eventId?: string; speakerId?: string }>();
  const { programs } = useProgramsData();
  const [speakers, setSpeakers] = useState<Speaker[] | null>(null);

  const filteredTopics = useMemo(() => {
    const topics: (Topic & { date: string })[] = [];
    if (speakerId) {
      programs?.forEach((program) => {
        program.eventDays.forEach((day) => {
          day.topics.forEach((topic) => {
            topic.speakers.forEach((speaker) => {
              if (`${speaker.id}` === speakerId) {
                topics.push({ ...topic, date: day.date });
              }
            });
          });
        });
      });
    } else {
      programs?.forEach((program) => {
        program.eventDays.forEach((day) => {
          day.topics.forEach((topic) => {
            if (`${topic.id}` === eventId) {
              topics.push({ ...topic, date: day.date });
            }
          });
        });
      });
    }
    return topics;
  }, [programs, speakerId, eventId]);

  useEffect(() => {
    if (speakerId) {
      const speaker = dataSpeakers.find((speaker) => `${speaker.id}` === speakerId);
      setSpeakers(speaker ? [speaker] : null);
    } else {
      const topicSpeakers =
        filteredTopics?.find((topic) => `${topic.id}` === eventId)?.speakers || null;

      if (topicSpeakers) {
        setSpeakers(topicSpeakers);
      }
    }
  }, [speakerId, eventId, filteredTopics]);

  const { hash } = useLocation();

  useEffect(() => {
    if (hash && filteredTopics) {
      const topicEl = document.body.querySelector(`#topic-${hash.substring(1)}`);
      topicEl?.scrollIntoView({ block: "start" });
    } else if (!hash) {
      document.body.scrollIntoView({ block: "start" });
    }
  }, [filteredTopics, hash]);

  return (
    <Layout header={<Header />} footer={<Footer />}>
      <StyledRow>
        <Col startMD={1} endMD={7}>
          {filteredTopics?.map((topic) => (
            <TopicContainer key={topic.id} id={`topic-${topic.id}`}>
              <h2>{topic.title}</h2>
              <TimeText>
                <div>{moment(topic.date).format()}</div>
                <div>
                  {formatTime(topic.timeFrom)} - {formatTime(topic.timeTo)}
                </div>
              </TimeText>
              <EventDescription dangerouslySetInnerHTML={{ __html: topic.description }} />
              {topic.speakers.length > 1 && (
                <>
                  <ParticipantsInfo>Debate participants:</ParticipantsInfo>
                  <UnderlinedLinkWrapper>
                    {topic.speakers.map((speaker, index) => (
                      <Fragment key={index}>
                        <Link to={`/speaker/${speaker.id}`}>{speaker.name}</Link>
                        {index < topic.speakers.length - 2 && <>, </>}
                        {index === topic.speakers.length - 2 && <> and </>}
                      </Fragment>
                    ))}
                  </UnderlinedLinkWrapper>
                </>
              )}
            </TopicContainer>
          ))}
        </Col>
        {!!speakers && (
          <Col startMD={speakers.length === 1 ? 8 : 1} endMD={11}>
            <SpeakerContainer>
              {speakers.map((speaker) => (
                <EventSpeaker key={speaker.id} speaker={speaker} isLink={speakers.length !== 1} />
              ))}
            </SpeakerContainer>
          </Col>
        )}
      </StyledRow>
    </Layout>
  );
};
