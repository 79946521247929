import { TextContainer } from "components/TextContainer";
import { TintedImage } from "components/TintedImage";
import { FC } from "react";
import styled from "styled-components";
import { Quote } from "types/quote";
import { queries } from "utils/queries";

const AuthorContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 48px;
  display: flex;

  ${queries.md} {
    margin-bottom: 112px;
  }
`;

const Avatar = styled(TintedImage)`
  margin-right: 16px;
`;

const AuthorName = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #7788c3;
  margin-bottom: 8px;
`;

const AuthorTitle = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: #7788c3;

  ${queries.md} {
    font-size: 14px;
    line-height: 24px;
  }
`;

export const FeedbackItem: FC<{ quote: Quote }> = ({ quote }) => {
  return (
    <div>
      <TextContainer>
        <p>{quote.quote}</p>
      </TextContainer>
      <AuthorContainer>
        {!!quote.speaker.picture && (
          <Avatar width={96} height={96} src={quote.speaker.picture.url} alt={quote.speaker.name} />
        )}
        <div>
          <AuthorName>{quote.speaker.name}</AuthorName>
          <AuthorTitle>{quote.speaker.title}</AuthorTitle>
        </div>
      </AuthorContainer>
    </div>
  );
};
