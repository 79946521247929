import { FC } from "react";
import styled from "styled-components";
import { queries } from "utils/queries";
import { ReactComponent as FacebookLogo } from "./logos/facebook_logo.svg";
import { ReactComponent as InstagramLogo } from "./logos/instagram_logo.svg";
import { ReactComponent as TwitterLogo } from "./logos/twitter_logo.svg";
import { ReactComponent as LinkedinLogo } from "./logos/linkedin_logo.svg";
import { GradientA } from "components/GradientLink";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const IconLink = styled(GradientA)`
  &:not(:last-child) {
    margin-right: 40px;
  }

  ${queries.onlySM} {
    margin-bottom: 32px;
  }
`;

const Icon = styled.div`
  svg {
    fill: #fff;
  }
`;

export const FooterSocials: FC = () => {
  return (
    <Container>
      <IconLink
        href="https://www.facebook.com/PragueCityDataCongress"
        target="_blank"
        rel="noreferrer"
      >
        <Icon>
          <FacebookLogo height={32} />
        </Icon>
      </IconLink>
      <IconLink href="https://www.instagram.com/smart.prague/" target="_blank" rel="noreferrer">
        <Icon>
          <InstagramLogo height={28} />
        </Icon>
      </IconLink>
      <IconLink href="https://twitter.com/Golemio_data" target="_blank" rel="noreferrer">
        <Icon>
          <TwitterLogo width={32} />
        </Icon>
      </IconLink>
      <IconLink
        href="https://www.linkedin.com/events/6826428987292602368/"
        target="_blank"
        rel="noreferrer"
      >
        <Icon>
          <LinkedinLogo width={32} />
        </Icon>
      </IconLink>
    </Container>
  );
};
