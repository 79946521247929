import { FC } from "react";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { Layout } from "components/Layout";
import { Row } from "components/Row";
import { Col } from "components/Col";
import styled from "styled-components";
import { ArchiveVideo } from "./sections/Videos/video";
import { PresentationsLinks } from "./sections/Presentations/index";
import { ArchiveLinks } from "./sections/Links/index";
import { PreviousYearsLinks } from "./sections/Links/ArchiveLink";

const HrRow = styled.div`
  padding-top: 2rem;
  padding-bottom: 1rem;
`;

const StyledRow = styled(Row)`
  padding-bottom: 128px;
`;
export const Archive2021: FC = () => {
  return (
    <Layout header={<Header />} footer={<Footer />}>
      <StyledRow>
        <Col startMD={1} endMD={11}>
          <ArchiveLinks />
          <HrRow>
            <hr />
          </HrRow>
          <PresentationsLinks />
          <ArchiveVideo />
          <PreviousYearsLinks />
        </Col>
      </StyledRow>
    </Layout>
  );
};
