import { Col } from "components/Col";
import { GradientA } from "components/GradientLink";
import { Row } from "components/Row";
import { Section } from "components/Section";
import { FC } from "react";
import styled from "styled-components";

const StyledTitle = styled.h2`
  font-size: 32px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 32px;
`;

const StyledRow = styled(Row)`
  background: #0e0a4a;
`;

const StyledSection = styled(Section)`
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
`;

const StyledText = styled.p`
  font-size: 20px;
  line-height: 32px;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const PdfLink = () => (
  <GradientA href="/pdf/pcdc_practical_info.pdf" target="_blank" rel="noreferrer">
    practical information
  </GradientA>
);

const PhoneLink = () => <GradientA href="tel:+420 774 367 182">+420 774 367 182</GradientA>;

const PCDCMailLink = () => (
  <GradientA href="mailto:pcdc@operatorict.cz">pcdc@operatorict.cz</GradientA>
);

const MailLink = () => (
  <GradientA href="mailto:riazanova@operatorict.cz">riazanova@operatorict.cz</GradientA>
);

export const ContactSection: FC = () => {
  return (
    <StyledRow>
      <Col startMD={1} endMD={11}>
        <StyledSection paddingMD={112} paddingLG={128}>
          <StyledTitle>Do you have any questions?</StyledTitle>
          <StyledText>
            Feel free to contact us! General queries should be sent to <PCDCMailLink />. If you are
            interested in active participation, you can connect directly with the program manager
            Valeriia Riazanova at <PhoneLink /> or at <MailLink />. She will also be your go-to
            person once the congress is happening.
          </StyledText>
          <StyledText>
            Here are some <PdfLink /> to make your trip to Prague easier.
          </StyledText>
        </StyledSection>
      </Col>
    </StyledRow>
  );
};
