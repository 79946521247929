import styled from "styled-components";
import { queries } from "utils/queries";

const Wave = styled.div`
  position: absolute;
  inset: 0;
  z-index: -1;
  background-repeat: no-repeat;

  ${queries.onlySM} {
    display: none;
  }
`;

export const RightWave1 = styled(Wave)`
  background-image: url(/images/waves/right.png);
  background-position: bottom right;
  background-size: 50%;
`;

export const RightWave2 = styled(Wave)`
  background-image: url(/images/waves/right2.png);
  background-position: bottom right;
  background-size: 40%;
  bottom: -350px;
  right: -350px;
`;

export const LeftWave1 = styled(Wave)`
  background-image: url(/images/waves/left.png);
  background-position: bottom top;
  background-size: 40%;
  left: -100px;
  top: -100px;
`;

export const LeftWave2 = styled(Wave)`
  background-image: url(/images/waves/left2.png);
  background-position: bottom left;
  background-size: 50%;
  left: -750px;
`;
