import { Col } from "components/Col";
import { Row } from "components/Row";
import { FC } from "react";
import styled from "styled-components";
import { queries } from "utils/queries";
import { HeaderLogo } from "./HeaderLogo";
import { InlineNavigation } from "./InlineNavigation";
import { MenuNavigation } from "./MenuNavigation";

const FlexRow = styled(Row)`
  align-items: center;
`;

const MarginCol = styled(Col)`
  margin-top: 24px;
  margin-bottom: 32px;

  ${queries.md} {
    margin-top: 52px;
    margin-bottom: 72px;
  }
`;

export const Header: FC = () => {
  return (
    <header>
      <FlexRow>
        <MarginCol startSM={1} endSM={2}>
          <HeaderLogo />
        </MarginCol>
        <Col startSM={2} endSM={11}>
          <InlineNavigation />
          <MenuNavigation />
        </Col>
      </FlexRow>
    </header>
  );
};
