


export const topicsDataPresentetion = [
    {
      title: "Dashboards data",
      subTitle: "Benedikt Kotmel",
      href: "https://drive.google.com/drive/folders/13RNtdyIY1qLB0mZGl7zOeujLPaPnWIq3?usp=sharing",
    
    },
    {
        title: "Value of urbam data",
        subTitle: "Petr Suška",
        href: "https://drive.google.com/file/d/10o5BXCWe2zRNM2Opc4_Mzy30RlHkiWdr/view?usp=sharing",
        
      },
    {
        
        title: "Prague talk slides",
        subTitle: "Nathan Coyle",
        href: "https://drive.google.com/drive/folders/1Hk0OA5KG0p3hX2pVsz8XyF2HHHF86MBp?usp=sharing",
        
    },
    {
        
        title: "Using GDS",
        subTitle: "Simon LU (online)",
        href: "https://drive.google.com/file/d/1FaWKS6t2ztqRyu2ADzvHzj7zYV7zoU2m/view?usp=sharing",
       
    },
    { 
        title: "Collaborations",
        subTitle: "Eva Pavlíková",
        href: "https://drive.google.com/file/d/1Eo2d4CzFEpeZLn9zHz-kBfuUTtVNzNqr/view?usp=sharing",
        

    },
    { 
        title: "Subdigital",
        subTitle: "Ján Pernecký",
        href: "https://drive.google.com/drive/folders/1Hk0OA5KG0p3hX2pVsz8XyF2HHHF86MBp?usp=sharing",
       

    },
    { 
        title: "Helsinki Design System",
        subTitle: "Ville Meloni",
        href: "https://drive.google.com/file/d/1LycmFghO_HiZHDigAnm-zD5V4jFq7Uzt/view?usp=sharing",
        

    },
    { 
        title: "Municipal Data Office",
        subTitle: "Màrius Boada Pla",
        href: "https://drive.google.com/file/d/1ephk4XkcjvTawhJebCvek3YwasHUJdcZ/view?usp=sharing",
        

    },
    { 
        title: "Prague City Data",
        subTitle: "Pau Garcia",
        href: "https://drive.google.com/drive/folders/1FCsYXkSoLIzFePWF19Pyym1dxs5XZPi5?usp=sharing",
        

    },
    { 
        title: "Data narratives",
        subTitle: "Tomáš Tholt",
        href: "https://drive.google.com/drive/folders/1-WySpdyMnPavXjXqym1bgG5LYyNB3jY7?usp=sharing",
        

    },
    { 
        title: "Urbania",
        subTitle: "Adam Pajgrt",
        href: "https://drive.google.com/drive/folders/1EF09Yx5Ww9GlG-izV-TV0_RYgPp2ky6K?usp=sharing",
        
    },
    { 
        title: "Prague city data",
        subTitle: "Susan Narduli",
        href: "https://drive.google.com/file/d/1yKmIxbUD2mY-nFCaRGr2XX5Ll0L1NgX_/view?usp=sharing",
       

    },
    { 
        title: "Dataveyes light",
        subTitle: "Maarten Sukel",
        href: "https://drive.google.com/drive/folders/1U1J-PtCnIh9y6YOdAGiLz1rG5V8LOvap?usp=sharing",
        

    },
    { 
        title: "Urban AI in Amsterdam",
        subTitle: "Caroline Goulard",
        href: "https://docs.google.com/presentation/d/16iOrEMTEujA68tpMas1ZziALikHbHclTkzCOMOJf_Xc/edit?usp=sharing",
      

    },
    { 
        title: "AI-experiments",
        subTitle: "Ville Meloni",
        href: "https://drive.google.com/file/d/1ZQ_gEev8JQPBo6vi5b3tBDf08bTWj6VD/view?usp=sharing",
        

    },
    { 
        title: "Data vs Numbness",
        subTitle: "Rodrigo Menegat",
        href: "https://docs.google.com/presentation/d/16iOrEMTEujA68tpMas1ZziALikHbHclTkzCOMOJf_Xc/edit?usp=sharing",
        

    },



  ];