import { FC } from "react";
import styled from "styled-components";
import { Section } from "components/Section";
import { Row } from "components/Row";
import { Col } from "components/Col";
import { queries } from "utils/queries";
import ReactPlayer from "react-player";
import "./style.css";

const Container = styled(Section)`
  padding-top: 32px;
  padding-bottom: 32px;

  ${queries.md} {
    padding-top: ${({ paddingMD }) => (paddingMD ? paddingMD : 64)}px;
    padding-bottom: ${({ paddingMD }) => (paddingMD ? paddingMD : 64)}px;
  }

  ${queries.lg} {
    padding-top: ${({ paddingLG }) => (paddingLG ? paddingLG : 96)}px;
    padding-bottom: ${({ paddingLG }) => (paddingLG ? paddingLG : 96)}px;
  }
`;

const SpanBlock = styled.span`
  // margin-left: 2rem;
  padding-bottom: 2.3rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  ${queries.md} {
    // margin-left: 7rem;
  }
  ${queries.onlySM} {
    font-size: 1.8rem;
  }
`;

const VideoSubTitle = styled.p`
  // margin-left: 2rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 125%;
  color: #7788c3;
  position: absolute;

  ${queries.md} {
    // margin-left: 7rem;
  }
  ${queries.onlySM} {
    font-size: 1.5rem;
  }
`;
const VideoBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  ${queries.onlySM} {
    padding-bottom: 20px;
  }
`;
const Wrapper = styled.div`
  position: relative;
  padding-top: 3rem;
  width: 100%;
`;

const MarginRow = styled(Row)`
  ${queries.lg} {
    margin-top: 2.5rem;
  }

  ${queries.onlySM} {
    padding-top: 10px;
    justify-content: center;
  }
`;

export const ArchiveVideo: FC = () => {
  return (
    <>
      <Container id="video">
        <SpanBlock>Videos</SpanBlock>
        <VideoSubTitle>Smart City Data Congress</VideoSubTitle>
        <VideoBox>
          <MarginRow>
            <Col startSM={0} endSM={12} startMD={0} endMD={12}>
              <Wrapper>
                <ReactPlayer
                  id="reactplayer"
                  height="50vw"
                  width="100%"
                  controls
                  url="https://www.youtube.com/watch?v=Z__CRR806bw"
                />
              </Wrapper>
            </Col>
          </MarginRow>
        </VideoBox>
        {/* <MarginRow /> */}
      </Container>
      <Container id="video-innovation">
        <VideoSubTitle>Data Innovation</VideoSubTitle>
        <VideoBox>
          <MarginRow>
            <Col startMD={0} endMD={6} startSM={0} endSM={12}>
              <Wrapper id="wrapper">
                <ReactPlayer
                  id="reactplayer"
                  width="100%"
                  height="360px"
                  controls
                  url="https://www.youtube.com/watch?v=v4bG91jUjPM"
                />
              </Wrapper>
            </Col>
            <Col startMD={6} endMD={12} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  id="reactplayer"
                  width="100%"
                  // height="30vw"
                  controls
                  url="https://www.youtube.com/watch?v=K9yB044ymII"
                />
              </Wrapper>
            </Col>

            <Col startMD={0} endMD={6} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  id="reactplayer"
                  width="100%"
                  // height="30vw"
                  controls
                  url="https://www.youtube.com/watch?v=CzlarGkU3gw"
                />
              </Wrapper>
            </Col>
          </MarginRow>
        </VideoBox>
      </Container>
      <Container id="video-opensource">
        <VideoSubTitle>Open Source</VideoSubTitle>
        <VideoBox>
          <MarginRow>
            <Col startMD={0} endMD={6} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://www.youtube.com/watch?v=bfjLae1YyiA"
                />
              </Wrapper>
            </Col>
            <Col startMD={6} endMD={12} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://www.youtube.com/watch?v=sL_xPSGlr9Q"
                />
              </Wrapper>
            </Col>
            <Col startMD={0} endMD={6} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://www.youtube.com/watch?v=qwn450Qz65E"
                />
              </Wrapper>
            </Col>
            <Col startMD={6} endMD={12} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://www.youtube.com/watch?v=f7f5xnUQfSYs"
                />
              </Wrapper>
            </Col>
          </MarginRow>
        </VideoBox>
      </Container>
      <Container id="video-opensource">
        <VideoSubTitle>Circular City</VideoSubTitle>
        <VideoBox>
          <MarginRow>
            <Col startMD={0} endMD={6} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://www.youtube.com/embed/GCZooyAoGJE"
                />
              </Wrapper>
            </Col>
            <Col startMD={6} endMD={12} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://youtu.be/X-V2tAE2amA"
                />
              </Wrapper>
            </Col>
            <Col startMD={0} endMD={6} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://youtu.be/BaL09Z08gVk"
                />
              </Wrapper>
            </Col>
            <Col startMD={6} endMD={12} startSM={0} endSM={12}>
              <Wrapper>
                <ReactPlayer
                  width="100%"
                  // height="20.3rem"
                  controls
                  url="https://youtu.be/bML49-hWoa4"
                />
              </Wrapper>
            </Col>
          </MarginRow>
        </VideoBox>
      </Container>
    </>
  );
};
