import { Col } from "components/Col";
import { Row } from "components/Row";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Speaker } from "types/speaker";
import { queries } from "utils/queries";
import { GradientLink } from "./GradientLink";
import { TintedImage } from "./TintedImage";
import dataSpeakers from "../data/speakers.json";

const SpeakersContainer = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  width: 100%;
  column-gap: 32px;
  row-gap: 120px;

  ${queries.onlySM} {
    row-gap: 40px;
  }
`;

const SpeakerLink = styled(GradientLink)`
  grid-column: auto;
  max-width: 336px;
  width: 100%;

  ${queries.onlySM} {
    margin-left: auto;
    margin-right: auto;
  }
`;

const SpeakerName = styled.h3`
  margin-top: 40px;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
`;

const SpeakerTitle = styled.p`
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
`;

interface Props {
  readonly count?: number;
  readonly className?: string;
}

const SpeakerLinkBox: FC<{ url: string }> = ({ children, url }) => (
  <SpeakerLink to={url}>{children}</SpeakerLink>
);

export const Speakers: FC<Props> = ({ count, className }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [speakers, setSpeakers] = useState<Speaker[]>([]);

  useEffect(() => {
    setIsLoading(true);
    setSpeakers(count ? (dataSpeakers as Speaker[]).slice(0, count) : (dataSpeakers as Speaker[]));
    setIsLoading(false);
  }, [count]);

  return (
    <Row className={className}>
      <Col startMD={1} endMD={11}>
        {isLoading && <>loading...</>}
          <SpeakersContainer>
            {speakers.map((speaker) => (
              <SpeakerLinkBox key={speaker.name} url={`/speaker/${speaker.id}`}>
                <TintedImage src={speaker.picture?.url} alt={speaker.name} />
                <SpeakerName>{speaker.name}</SpeakerName>
                <SpeakerTitle>{speaker.title}</SpeakerTitle>
              </SpeakerLinkBox>
            ))}
          </SpeakersContainer>
      </Col>
    </Row>
  );
};
