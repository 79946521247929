import { createContext, FC, useContext, useEffect, useMemo, useState } from "react";
import { Program, Topic } from "types/programType";
import dataPrograms from "../data/programs.json";

interface ContextValue {
  programs: Program[] | null;
  isLoading: boolean;
}

const ProgramContext = createContext<ContextValue>({
  programs: null,
  isLoading: false,
});

const sortTopics = (topicA: Topic, topicB: Topic) => {
  if (topicA.timeFrom < topicB.timeFrom) {
    return -1;
  } else if (topicA.timeFrom > topicB.timeFrom) {
    return 1;
  } else {
    if (topicA.timeTo < topicB.timeTo) {
      return -1;
    } else if (topicA.timeTo > topicB.timeTo) {
      return 1;
    } else {
      return 0;
    }
  }
};

export const ProgramsProvider: FC = ({ children }) => {
  const [programs, setPrograms] = useState<Program[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const sortedProgram = (dataPrograms as Program[]).map((program) => ({
      ...program,
      eventDays: program.eventDays.map((day) => ({
        ...day,
        topics: day.topics.sort(sortTopics),
      })),
    }));
    setPrograms(sortedProgram);
    setIsLoading(false);
  }, []);

  const contextValue = useMemo(() => {
    return {
      programs,
      isLoading,
    };
  }, [programs, isLoading]);

  return <ProgramContext.Provider value={contextValue}>{children}</ProgramContext.Provider>;
};

export const useProgramsData = () => {
  const programData = useContext(ProgramContext);

  return programData;
};
