import { FC } from "react";
import { Col } from "components/Col";
import { Row } from "components/Row";
import { Section } from "components/Section";
import { TextWithArrowIcon } from "components/TextWithArrowIcon";
import { UnderlinedText } from "components/UnderlinedText";
import { WithGradient } from "components/WithGradient";
import styled from "styled-components";
import { queries } from "utils/queries";
import { GradientA } from "components/GradientLink";
import { configuration } from "configuration";

const Title = WithGradient("h2");

const TitleWithMargin = styled(Title)`
  padding-bottom: 64px;

  ${queries.onlySM} {
    font-size: 24px;
  }
`;

const SectionWithBG = styled(Section)`
  background: linear-gradient(102.92deg, #131560 -10.51%, rgba(41, 56, 139, 0.6) 107.96%),
    url(/images/soon_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
`;

const GradientAWithMargin = styled(GradientA)`
  margin-bottom: 32px;
  display: inline-block;
`;

export const ComingSoon: FC = () => {
  return (
    <SectionWithBG id="coming-soon" paddingMD={112} paddingLG={128}>
      <Row>
        <Col startMD={1} endMD={8}>
          <TitleWithMargin>
            Do you want to see more? Check-out what have we been talking about!
          </TitleWithMargin>
          <GradientAWithMargin
            href={configuration.PRESENTATIONS_URL}
            target="_blank"
            rel="noreferrer"
          >
            <TextWithArrowIcon size={24}>
              <UnderlinedText>Check out presentations from PCDC 2021</UnderlinedText>
            </TextWithArrowIcon>
          </GradientAWithMargin>
          <GradientA
            href="https://www.youtube.com/channel/UCjg90-v8LpPeiRgg7Pz8eQg/playlists"
            target="_blank"
            rel="noreferrer"
          >
            <TextWithArrowIcon size={24}>
              <UnderlinedText>Check out the previous years</UnderlinedText>
            </TextWithArrowIcon>
          </GradientA>
        </Col>
      </Row>
    </SectionWithBG>
  );
};
