import { FC } from "react";
import styled from "styled-components";
import { Section } from "components/Section";
import { Row } from "components/Row";
import { Col } from "components/Col";
import { queries } from "utils/queries";
import { WaveFormComponent } from "./Waveform";
import { dataPodcasts2020 } from "./audioData";

const Container = styled(Section)`
  padding-top: 32px;
  padding-bottom: 32px;

  ${queries.md} {
    padding-top: ${({ paddingMD }) => (paddingMD ? paddingMD : 64)}px;
    padding-bottom: ${({ paddingMD }) => (paddingMD ? paddingMD : 64)}px;
  }

  ${queries.lg} {
    padding-top: ${({ paddingLG }) => (paddingLG ? paddingLG : 96)}px;
    padding-bottom: ${({ paddingLG }) => (paddingLG ? paddingLG : 96)}px;
  }
  ${queries.onlySM} {
    padding-top: ${({ paddingMD }) => (paddingMD ? paddingMD : 32)}px;
    padding-bottom: ${({ paddingMD }) => (paddingMD ? paddingMD : 32)}px;
  }
`;

const SpanBlock = styled.span`
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 2rem;
  line-height: 150%;
  color: #ffffff;
  ${queries.md} {
    font-size: 2rem;
  }
  ${queries.onlySM} {
    font-size: 1.5rem;
  }
`;

const Margin = styled.div`
  margin-top: 2.5rem;
  ${queries.onlySM} {
    margin-top: 1.5rem;
  }
`;

export const Podcast: FC = () => {
  return (
    <Container id="podcast">
      <SpanBlock>City data podcasts</SpanBlock>
      <Row>
        <Margin />
        {dataPodcasts2020.map(({ title, author, img, audio }, index) => (
          <Col
            key={index}
            startLG={index % 2 === 0 || index === 0 ? 0 : 6}
            endLG={index % 2 === 0 || index === 0 ? 6 : 12}
            startMD={index % 2 === 0 || index === 0 ? 0 : 0}
            endMD={index % 2 === 0 || index === 0 ? 12 : 12}
            startSM={index % 2 === 0 || index === 0 ? 0 : 0}
            endSM={index % 2 === 0 || index === 0 ? 12 : 12}
          >
            <WaveFormComponent
              waveSurfParams={{ img, title, author, audio }}
              style={{ width: "90vw", height: "20px" }}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
