import { FC, useCallback, useEffect, useState } from "react";
import { Col } from "components/Col";
import { Row } from "components/Row";
import { RelativeSection } from "components/Section";
import { SmallerTitle } from "components/SmallerTitle";
import styled from "styled-components";
import { Quote } from "types/quote";
import { FeedbackItem } from "./FeedbackItem";
import { ArrowIcon } from "components/ArrowIcon";
import { RightWave2 } from "components/Waves";
import dataFeedbacks from "../../../../data/feedbacks.json";

const FeedbackSection = styled(RelativeSection)`
  overflow: hidden;
`;

const GradientBG = styled.div`
  background: linear-gradient(102.34deg, #141560 -2.47%, #131560 108.49%);
  z-index: -2;
  position: absolute;
  inset: 0;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;

  & svg {
    fill: #7788c3;
  }

  &:hover svg {
    fill: url(#paint0_linear);
  }
`;

const RotatedButton = styled(ArrowButton)`
  margin-right: 32px;

  & svg {
    transform: rotate(180deg);
  }
`;

export const Feedback: FC = () => {
  const [feedbacks, setFeedbacks] = useState<Quote[]>([]);
  const [selectedFeedback, setSelectedFeedback] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const selectFeedback = useCallback(
    (feedbackIndex: number) => {
      if (feedbackIndex < 0) {
        setSelectedFeedback(Math.max(feedbacks.length - 1, 0));
      } else if (feedbackIndex >= feedbacks.length) {
        setSelectedFeedback(0);
      } else {
        setSelectedFeedback(feedbackIndex);
      }
    },
    [feedbacks]
  );

  useEffect(() => {
    setIsLoading(true);
    setFeedbacks(dataFeedbacks as Quote[]);
    setIsLoading(false);
  }, []);

  return (
    <FeedbackSection id="feedback">
      <GradientBG />
      <RightWave2 />
      <Row>
        <Col startMD={1} endMD={3}>
          <SmallerTitle>What do past speakers and attendees have to say?</SmallerTitle>
        </Col>
        <Col startMD={5} endMD={11}>
          {isLoading && <>loading...</>}
          {!isLoading && feedbacks.length > 0 && (
            <>
              <FeedbackItem quote={feedbacks[selectedFeedback]} />
              <RotatedButton type="button" onClick={() => selectFeedback(selectedFeedback - 1)}>
                <ArrowIcon />
              </RotatedButton>
              <ArrowButton type="button" onClick={() => selectFeedback(selectedFeedback + 1)}>
                <ArrowIcon />
              </ArrowButton>
            </>
          )}
        </Col>
      </Row>
    </FeedbackSection>
  );
};
