import { FC } from "react";
import { TextWithArrowIcon } from "components/TextWithArrowIcon";
import { Col } from "components/Col";
import { Row } from "components/Row";
import { RelativeSection } from "components/Section";
import styled, { css } from "styled-components";
import { UnderlinedText } from "components/UnderlinedText";
import { WithGradient } from "components/WithGradient";
import { queries } from "utils/queries";
import { GradientA } from "components/GradientLink";
import { configuration } from "configuration";

const Title = styled.h2`
  font-weight: bold;
  margin-bottom: 32px;

  font-size: 20px;
  line-height: 32px;

  ${queries.md} {
    font-size: 36px;
    line-height: 48px;
  }
`;

const SpanBlock = styled.span`
  display: block;
`;

const GradientSpan = WithGradient(SpanBlock);

const Background = styled.div<{ showRegistration: boolean }>`
  z-index: -1;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(180deg, #100d5b 13.57%, rgba(22, 26, 99, 0) 162.21%),
    url(/images/location_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 534px;

  ${queries.md} {
    ${({ showRegistration }) =>
      showRegistration &&
      css`
        height: 962px;
      `}
  }

  ${({ showRegistration }) =>
    !showRegistration &&
    css`
      height: auto;
      top: 0;
    `}
`;

const RelativeSectionWithMargin = styled(RelativeSection)`
  margin-top: 128px;
`;

export const Location: FC = () => {
  return (
    <RelativeSectionWithMargin id="venue">
      <Background showRegistration={configuration.SHOW_REGISTRATION} />
      <Row>
        <Col startMD={1} endMD={11}>
          <Title>
            <SpanBlock>The congress is happening in</SpanBlock>
            <GradientSpan>CAMP - Vyšehradská 51, Prague 2</GradientSpan>
            <GradientSpan>Emmaus Monastery complex</GradientSpan>
          </Title>
          <GradientA href="https://goo.gl/maps/AMAvQkCxhWx1BVDz6" target="_blank" rel="noreferrer">
            <TextWithArrowIcon size={24}>
              <UnderlinedText>Show on Google Maps</UnderlinedText>
            </TextWithArrowIcon>
          </GradientA>
        </Col>
      </Row>
    </RelativeSectionWithMargin>
  );
};
