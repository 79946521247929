import { FC } from "react";
import styled from "styled-components";
import { ReactComponent as Icon } from "./marker.svg";

const StyledIcon = styled(Icon)`
  margin-right: 16px;
  margin-bottom: -3px;
`;

export const MarkerIcon: FC<{ size?: number }> = ({ size = 24 }) => {
  return <StyledIcon height={size} />;
};
