import { FC } from "react";
import styled from "styled-components";
import { Col } from "components/Col";
import { Row } from "components/Row";
import { PrimaryButton } from "components/Buttons";
import { TextWithArrowIcon } from "components/TextWithArrowIcon";
import { MarkerIcon } from "components/MarkerIcon";
import { WithGradient } from "components/WithGradient";
import { queries } from "utils/queries";
import { GradientA } from "components/GradientLink";
import { configuration } from "configuration";

const NowrapCol = styled(Col)`
  ${queries.md} {
    white-space: nowrap;
    z-index: 1;
  }
`;

const SpanBlock = styled.span`
  display: block;
`;

const VideoWrapper = styled(Col)`
  position: relative;
  overflow: hidden;

  ${queries.onlySM} {
    display: none;
  }
`;

const Video = styled.video`
  filter: grayscale(100%);
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
`;

const VideoMask = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(103.92deg, #171e65 -5.75%, rgba(30, 39, 163, 0.62) 98.16%);
  opacity: 0.7;
`;

const Subtitle = styled.p`
  &,
  & a {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    display: inline-block;

    font-size: 16px;
    line-height: 40px;

    ${queries.md} {
      font-size: 24px;
    }
  }

  & a {
    line-height: 20px;
    text-decoration: underline;
  }

  & a:hover {
    color: #1febaf;
  }
`;

const GradientSpanBlock = WithGradient(
  styled(SpanBlock)`
    position: absolute; // to not be hidden behind video
  `
);

// to fill hole made by absolutely positioned text
const InvisibleText = styled(SpanBlock)`
  visibility: hidden;
`;

const RelativeH1 = styled.h1`
  position: relative;
`;

const FirstSubtitle = styled(Subtitle)`
  margin-bottom: 8px;

  ${queries.md} {
    margin-top: 60px;
    margin-bottom: 16px;
  }

  ${queries.lg} {
    margin-top: 104px;
  }
`;

const SecondSubtitle = styled(Subtitle)`
  margin-top: 40px;

  ${queries.onlySM} {
    line-height: 24px;
  }

  ${queries.md} {
    margin-top: 56px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;

  ${queries.md} {
    margin-bottom: 16px;
    min-height: 72px;
  }

  ${queries.lg} {
    margin-bottom: 96px;
  }
`;

const MarkerTextWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const Intro: FC = () => {
  return (
    <Row>
      <NowrapCol startMD={1} endMD={5}>
        <FirstSubtitle>Looking beyond the numbers</FirstSubtitle>
        <RelativeH1>
          <SpanBlock>Prague City</SpanBlock>
          <GradientSpanBlock>Data Congress</GradientSpanBlock>
          <InvisibleText>Data Congress</InvisibleText>
        </RelativeH1>
        <SecondSubtitle>
          <MarkerTextWrapper>
            <MarkerIcon />
            <span>
              <span>26–27 October 2021, </span>
              <GradientA
                href="https://goo.gl/maps/AMAvQkCxhWx1BVDz6"
                target="_blank"
                rel="noreferrer"
              >
                CAMP - Vyšehradská 51, Praha 2
              </GradientA>
              <span> or </span>
              <GradientA href="https://vimeo.com/event/1382472" target="_blank" rel="noreferrer">
                online
              </GradientA>
            </span>
          </MarkerTextWrapper>
        </SecondSubtitle>
        <ButtonWrapper>
          {!!configuration.SHOW_REGISTRATION && (
            <GradientA href="#registration">
              <PrimaryButton type="button">
                <TextWithArrowIcon>Book your seat</TextWithArrowIcon>
              </PrimaryButton>
            </GradientA>
          )}
        </ButtonWrapper>
      </NowrapCol>
      <VideoWrapper startMD={5} endMD={12}>
        <Video autoPlay={true} muted={true} loop={true} playsInline={true}>
          <source src="/videos/CDC19_SHORT_online_v2.webm" type="video/webm" />
          <source src="/videos/CDC19_SHORT_online_v2.mp4" type="video/mp4" />
        </Video>
        <VideoMask />
      </VideoWrapper>
    </Row>
  );
};
