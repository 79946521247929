import { GradientA } from "components/GradientLink";
import { FC } from "react";
import styled from "styled-components";

const TosLink: FC = () => {
  return (
    <GradientA
      href="https://operatorict.cz/en/personal-data-protection/"
      target="_blank"
      rel="noreferrer"
    >
      terms and conditions
    </GradientA>
  );
};

const CovidGovLink: FC = () => {
  return (
    <GradientA href="https://covid.gov.cz/en/" target="_blank" rel="noreferrer">
      https://covid.gov.cz/en/
    </GradientA>
  );
};

const StyledText = styled.p`
  font-size: 14px;
  line-height: 24px;
`;

export const AgreementText: FC<{ attendInPerson: boolean }> = ({ attendInPerson }) => {
  if (attendInPerson) {
    return (
      <StyledText>
        By submitting this registration, congress participant simultaneously confirms and agrees
        with <TosLink /> for personal data processing exclusively for the needs of the congress or
        for the needs of coronavirus tracing. Upon arrival, the participant agrees to follow current
        preventive measures to help stop the spread of coronavirus. We reserve the right to limit
        the number of participants, modify or cancel the event. Failure to accept terms and
        conditions means that you will not be able to register to the Prague City Data Congress.
        Follow the latest information on covid restrictions in the Czech Republic on{" "}
        <CovidGovLink />.
      </StyledText>
    );
  } else {
    return (
      <StyledText>
        By submitting this registration, congress participant simultaneously confirms and agrees
        with <TosLink /> for personal data processing exclusively for the needs of the congress. We
        reserve the right to modify or cancel the event. Failure to accept terms and conditions
        means that you will not be able to register to the Prague City Data Congress.
      </StyledText>
    );
  }
};
