import styled from "styled-components";

export const InputContainer = styled.div`
  border-bottom: 1px solid #7788c3;
  margin-bottom: 32px;

  &:focus-within {
    border-bottom: 1px solid #fff;
  }
`;
